import React from "react"
import { Layout, Awards, Award, Nominations, Nomination, AboutAuthor, KnjizevnaDjela, KnjizevnoDjelo, Title, Scenarij, Scenariji, AutorskiAnimiraniFilm, AutorskiAnimiraniFilmovi, FilmoviGlavniAnimator, FilmGlavniAnimator, FestivaliFilm, FestivaliFilmovi, KnjigaIlustrirao, KnjigeIlustrirao, MedunarodnaIzlozba, MedunarodneIzlozbe } from "../components/index"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';

export default () => (
  <div className="authors">
    <Layout>
          <Helmet>
                <title>Autori - Umjetnička organizacija Kreativna Kuća</title>
                <meta name='title' content='Autori - Umjetnička organizacija Kreativna Kuća' />
                <meta name='description' content='KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
      <Title title='Autori' subtitle='Zanimljivosti o autorima, njihovom životu i uspjesima'></Title>
      <div className="authors-wrapper">
        <AboutAuthor className='author-nena' idd='nena-loncar' title='Nena Lončar'>
          <p>Scenaristica i višestruko nagrađivana književnica, rođena je 1968. godine u Krapini gdje i danas živi.</p>
          <p>Članica je nekih društava, ali ponajviše pripada svojoj obitelji i sebi samoj. Obožava čitati, a zapisivanjem  kroti razbarušene misli.</p>
          <p>Dok stvara priče, Nena se igra riječima. Jezik je za nju kao Svemir - čudesan i beskonačan. Maštom širi granice naših svjetova, a humorom se bori protiv predrasuda i gluposti.</p>
          <p>U svojem radu istražuje inovativne pristupe. Ni ludizam joj nije stran. Rado piše za djecu i uživa u tome.</p>
        </AboutAuthor>
        <KnjizevnaDjela>
          <KnjizevnoDjelo>
            “<strong>Šest guba čarolija i jedan užas na kvadrat</strong>”, <em>HDKDM</em>, 2014. - <strong>roman</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Bio sam pas i opet sam</strong>”, <em>HDKDM</em>, 2015. - <strong>roman</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Bajka o šeširu koji je imao čovjeka</strong>”, <em>HDKDM</em>, 2017.  - <strong>alegorija</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Sve o Evi</strong>”, <em>Ibis grafika</em>, 2017. - <strong>roman</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Konradova prodavaonica ptica</strong>”, <em>HDKDM</em>, 2018. - <strong>slikovnica</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Grad na zrnu soli</strong>”, <em>Kreativna kuća</em>, 2018. - <strong>roman</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Riba koja nije umjela plakati</strong>”, <em>Kreativna kuća</em>, 2019. - <strong>slikovnica</strong>
          </KnjizevnoDjelo>
          <KnjizevnoDjelo>
            “<strong>Najhrabriji miš na svijetu</strong>”, <em>Kreativna kuća</em>, 2019. - <strong>slikovnica</strong>
          </KnjizevnoDjelo>
        </KnjizevnaDjela>

        <Scenariji>
          <Scenarij>Scenariji za <em>četiri epizode</em> animirane serije “<strong>Profesor Balthazar</strong>”, <em>2005.</em>
            <ul className='scenarij-sublist'>
              <li>”<strong>Mali problem</strong>”</li>
              <li><strong>”Sveopća praznina</strong>”</li>
              <li>”<strong>Kaktus Hombre</strong>”</li>
              <li>”<strong>Robot Spirit</strong>”</li>
            </ul>
          </Scenarij>
          <Scenarij>scenarij za <em>animirani film</em> ”<strong>Konradova prodavaonica ptica</strong>” (potpora <em>HAVC</em>-a), <em>2018.</em></Scenarij>
          <Scenarij>scenarij za <em>animirani film</em> “<strong>Tubist</strong>” (potpora <em>HAVC</em>-a), <em>2019.</em></Scenarij>
        </Scenariji>

        <Awards>
          <Award>nagrada <b>“Zvonko”</b> za roman <i>“Šest guba čarolija i jedan užas na kvadrat”</i></Award>
          <Award>nagrada <b>“Grigor Vitez”</b> za roman <i>“Sve o Evi”</i></Award>
          <Award>nagrada<b>“Zvonko”</b> za roman <i>“Bio sam pas i opet sam”</i></Award>
          <Award>međunarodna nagrada <b>“Mali princ”</b> za roman <i>“Sve o Evi”</i></Award>
        </Awards>

        <Nominations>
          <Nomination>
            nominacije za nagrade <strong>“Grigor Vitez”</strong> i <strong>“Mali princ”</strong> za roman “Bio sam pas i opet  sam”
          </Nomination>
          <Nomination>
            nominacija za nagradu <strong>“Anto Gardaš”</strong> za roman “Usvojili smo baku i djeda”
          </Nomination>
          <Nomination>
            nominacije za nagrade <strong>“Anto Gardaš”</strong> i <strong>“Mato Lovrak”</strong> za roman “Sve o Evi”
          </Nomination>
          <Nomination>
            nominacija za nagradu <strong>“Ovca u kutiji”</strong> za slikovnicu “Konradova prodavaonica ptica”
          </Nomination>
          <Nomination>
            priznanje <strong>“Hrvatska lijepa knjiga za 2017”</strong> za alegoriju “Bajka o šeširu koji je imao čovjeka” dodijeljeno u sklopu međunarodnog natječaja “Best Book Design from all over the World”
          </Nomination>
          <Nomination>
            romani “Bio sam pas i opet sam” i “Sve o Evi” uvršteni su u <strong>“Nacionalni kviz za poticanje čitanja”</strong> za 2016. i 2018. godinu
          </Nomination>
          <Nomination>
            sva do sada objavljena književna djela uvrštena su u <strong>“Katalog dobrih knjiga”</strong> koje se od strane struke preporučuju za čitanje
            </Nomination>
          <Nomination>
            dobitnica je književne stipendije Ministarstva kulture za 2020. godinu
            </Nomination>
        </Nominations>
  
        <AboutAuthor className='author-marijan' idd='marijan-loncar' title='Marijan Lončar'>
          <p>Marijan Lončar (1967) nagrađivani  je animator i autor animiranih filmova. </p>
          <p>Član je HZSU-a, HDFD-a i ASIFA-e.</p>
          <p>Tijekom godina, radio je kao glavni animator na mnogim filmovima od kojih su najznačajniji: Mali leteći medvjedići, Čudnovate zgode šegrta Hlapića, Profesor Baltazar,  Kontakt,  Špica za svjetski festival animiranog filma - Animafest.</p>
          <p>Ostvario je i nekoliko autorskih animiranih filmova: Sretan cvrčak, Banana, Božićna bajka.</p>
        </AboutAuthor>
        <Awards>
          <Award>Nagrada <b>GRAND PRIX</b> na <i>Danu hrvatske animacije</i> 2004. godine za film <strong>SRETAN CVRČAK</strong></Award>
        </Awards>

        <AutorskiAnimiraniFilmovi>
          <AutorskiAnimiraniFilm>"Sretan cvrčak"</AutorskiAnimiraniFilm>
          <AutorskiAnimiraniFilm> “Banana”</AutorskiAnimiraniFilm>
          <AutorskiAnimiraniFilm> “Božićna bajka”</AutorskiAnimiraniFilm>
        </AutorskiAnimiraniFilmovi>


        <FilmoviGlavniAnimator>
          <FilmGlavniAnimator>“Mali leteći medvjedići”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Čudnovate zgode Šegrta Hlapića”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Profesor Baltazar”</FilmGlavniAnimator>
          <FilmGlavniAnimator> “Kontakt”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Banana”</FilmGlavniAnimator>
          <FilmGlavniAnimator>“Špica za svjetski festival animiranog filma Animafest”</FilmGlavniAnimator>
        </FilmoviGlavniAnimator>

        <FestivaliFilmovi>
          <FestivaliFilm> Tabor film festival</FestivaliFilm>
          <FestivaliFilm> Dani hrvatskog filma</FestivaliFilm>
          <FestivaliFilm> Festival jednominutnih filmova u Požegi</FestivaliFilm>
          <FestivaliFilm>Dan hrvatske animacije</FestivaliFilm>
          <FestivaliFilm> Animafest Zagreb</FestivaliFilm>
        </FestivaliFilmovi>

        <KnjigeIlustrirao>
          <KnjigaIlustrirao> "Bio sam pas i opet sam"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Usvojili smo baku i djeda"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Sve o Evi"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Konradova prodavaonica ptica"</KnjigaIlustrirao>
          <KnjigaIlustrirao>"Kako je Flegmarin postao kapetan"</KnjigaIlustrirao>
        </KnjigeIlustrirao>

        <MedunarodneIzlozbe>
          <MedunarodnaIzlozba>Knjiga "Bajka o šeširu koji je imao čovjeka" je u sklopu međunarodnog natječaja ‘’Best Book Design from all over the World’’ izabrana za jednu od ‘’Hrvatskih lijepih knjiga’’ za 2017. godinu te je kao takva sudjelovala na izložbi u Leipzigu, a bit će i u stalnoj postavi izložbe ‘’Book Art International’’ koja se na jesen otvara u sklopu sajma knjiga u Frankfurtu.</MedunarodnaIzlozba>
          <MedunarodnaIzlozba>Izložba karikatura u “50th World Gallery of Cartoons Skopje 2018”</MedunarodnaIzlozba>
        </MedunarodneIzlozbe>
      </div>
    </Layout>
  </div>
)